import API from './api'
// import axios from 'axios'
import { axiosWithErrorHandler } from './axios'

export const GET_LOG_REQUEST = async (gameId: string,startDate: string, endDate: string,page: number, limit: number) => {
    let urlFilterId = ""
    if(gameId) {
        urlFilterId = `&gameId=${gameId}`
    }
    const response = await axiosWithErrorHandler(`GET`, `${API.API_URL}${API.LOG_REQUEST}/?page=${page}&pageSize=${limit}${urlFilterId}&startDate=${startDate}&endDate=${endDate}`)
    return response.data;
};

export const GET_GAME_TRANSACTION = async (gameId: string,startDate: string, endDate: string,page: number, limit: number) => {
    let urlFilterId = ""
    if(gameId) {
        urlFilterId = `&gameId=${gameId}`
    }
    const response = await axiosWithErrorHandler(`GET`, `${API.API_URL}${API.GET_GAME_LOG}/?page=${page}&pageSize=${limit}${urlFilterId}&startDate=${startDate}&endDate=${endDate}`)
    return response.data;
};

export const RETRY_OUTSTANDING = async (state) => {
    const response = await axiosWithErrorHandler(`POST`, `${API.API_URL}${API.RETRY_OUTSTANDING}`, state)
    return response.data;
};

export const GET_GAME_CALCULATE = async (gameId: string,startDate: string, endDate: string,page: number, limit: number) => {
    let urlFilterId = ""
    if(gameId) {
        urlFilterId = `&gameId=${gameId}`
    }
    const response = await axiosWithErrorHandler(`GET`, `${API.API_URL}${API.GET_RECALCULATE_LOG}/?page=${page}&pageSize=${limit}${urlFilterId}&startDate=${startDate}&endDate=${endDate}`)
    return response.data;
};

export const RE_CALUCLATE = async (state) => {
    const response = await axiosWithErrorHandler(`POST`, `${API.API_URL}${API.RETRY_OUTSTANDING}`, state)
    return response.data;
};