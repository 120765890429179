import { Component, Vue } from "vue-property-decorator";
import DatePicker from 'vue2-datepicker';
import moment from 'moment';
import Model from "@/models/Model";
import VModal from 'vue-js-modal'

import 'vue2-datepicker/index.css';
import { GET_GAME_TRANSACTION, RETRY_OUTSTANDING } from "../../../api/log-request";

Vue.use(VModal, { componentName: 'modal' })
@Component({
    components: {
        DatePicker
    },
    filters: {
        currency(value: any) {
            const val = (value / 1).toFixed(2).replace('.', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        dateTime(val: any) {
            return val ? moment(val).local().format('DD-MM-YYYY HH:mm:ss') : ''
        }
    }
})
default class Outstanding extends Vue {
    $axios: any;
    private model: Model;
    private outstandingData: any = []
    public dateFilter = {
        startDate: moment().local().startOf('day').toDate(),
        endDate: moment().local().endOf('day').toDate()
    }

    private paginate = {
        page: 1,
        limit: 10
    }
    private pageSize = 0;
    private paginateShow = false;
    private dataLoading = false;
    private filterGameId = ""
    private jsonString = ""

    constructor() {
        super();
        this.model = new Model();
    }

    async mounted() {

        this.addRouteName()
        this.dataLoading = true
        await this.getOutstanding(this.paginate.page, this.paginate.limit)
        this.dataLoading = false
    }

    private addRouteName() {
        this.model.Name = this.$route.name;
        this.$store
            .dispatch("addRouteName", this.model)
    }

    public async filterOutstandingData() {
        this.paginate.page = 1
        await this.getOutstanding(this.paginate.page, this.paginate.limit)
    }

    public async outStandingFn(gameId) {
        const loader = this.$loading.show({
            color: '#CFA137',
            loader: 'spinner',
            width: 145,
            height: 235,
            backgroundColor: '#000000',
            opacity: 0.5,
        });

        const state = {
            gameId : gameId,
            method : "re-outstanding"
        }

        await RETRY_OUTSTANDING(state)

        await this.getOutstanding(this.paginate.page, this.paginate.limit)

        loader.hide()


    }

    public async selectDate(option: string) {
        switch (option) {
            case 'option1':
                this.dateFilter.startDate = moment(this.dateFilter.startDate).subtract(1, 'days').toDate()
                this.dateFilter.endDate = moment(this.dateFilter.endDate).subtract(1, 'days').toDate()
                break;
            case 'option2':
                this.dateFilter.startDate = moment().local().startOf('day').toDate()
                this.dateFilter.endDate = moment().local().endOf('day').toDate()
                break;
            case 'option3':
                this.dateFilter.startDate = moment().local(true).startOf('day').subtract(1, 'days').toDate()
                this.dateFilter.endDate = moment().local(true).endOf('day').subtract(1, 'days').toDate()
                break;
            case 'option4':
                this.dateFilter.startDate = moment().local(true).startOf('week').toDate()
                this.dateFilter.endDate = moment().local(true).endOf('week').toDate()
                break;
            case 'option5':
                this.dateFilter.startDate = moment().local(true).subtract(1, 'week').startOf('week').toDate()
                this.dateFilter.endDate = moment().local(true).subtract(1, 'week').endOf('week').toDate()
                break;
            case 'option6':
                this.dateFilter.startDate = moment().local(true).startOf('month').toDate()
                this.dateFilter.endDate = moment().local(true).endOf('month').toDate()
                break;
            case 'option7':
                this.dateFilter.startDate = moment().local(true).subtract(1, 'months').startOf('month').toDate()
                this.dateFilter.endDate = moment().local(true).subtract(1, 'months').endOf('month').toDate()
                break;
            case 'option8':
                this.dateFilter.startDate = moment(this.dateFilter.startDate).add(1, 'days').toDate()
                this.dateFilter.endDate = moment(this.dateFilter.endDate).add(1, 'days').toDate()
                break;
            default:
                break;
        }
        await this.getOutstanding(this.paginate.page, this.paginate.limit)
    }

    public async clickCallback(number: number) {
        this.paginate.page = number
        await this.getOutstanding(this.paginate.page, this.paginate.limit)
    }

    private async getOutstanding(page: number, limit: number) {
        this.outstandingData = []
        const loader = this.$loading.show({
            color: '#CFA137',
            loader: 'spinner',
            width: 145,
            height: 235,
            backgroundColor: '#000000',
            opacity: 0.5,
        });
        const lists = await GET_GAME_TRANSACTION(this.filterGameId, (this.dateFilter.startDate).toISOString(), (this.dateFilter.endDate).toISOString(),page, limit)
        this.outstandingData = lists.data[0];

        if (this.outstandingData.metadata.length >= 1) {
            const num = (this.paginate.page * this.paginate.limit)
            for (let i = 0, k = num; i < this.outstandingData.metadata.length; i++, k++) {
                this.outstandingData.metadata[i].no = k - this.paginate.limit
            }
            if (this.outstandingData.pageInfo[0].count > this.paginate.limit) {
                this.paginateShow = true
            }
            this.pageSize = this.outstandingData.pageInfo[0].size;
        } else {
            this.outstandingData = []
            this.paginateShow = false
        }
        loader.hide()
    }
}

export default Outstanding